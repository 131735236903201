import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
